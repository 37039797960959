module.exports = [{
      plugin: require('/opt/build/repo/website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/website/src/components/Layout.jsx"}},
    },{
      plugin: require('/opt/build/repo/website/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/opt/build/repo/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
